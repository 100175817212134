import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import SavingAccountStatementListPdf from "@/components/page/saving-account-statement-list-pdf";
import moment from "moment";
export default {
  name: "TSavingAccountDetailAccountStatement",
  components: {
    SavingAccountStatementListPdf,
  },
  computed: {
    officeCodeSession() {
      let getSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).officeCode;
      return getSession;
    },
    minDateRange() {
      return new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1)
        .toISOString()
        .substr(0, 10);
    },
  },

  data() {
    return {
      property: {
        animation: {
          CheckingSavingAccount: {
            isDownloadingFile: false,
            isLoading: false,
          },
        },
        modal: {
          showModalCheckingSavingAccount: false,
          showModalResultListSavingAccount: false,
        },
        listElement: {
          accountStatement: {
            message: "",
            downloading: false,
          },
          savingAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          accountNumber: "",
          dateFrom: "",
          dateTo: "",
          sorting: "",
        },
        accessories: {
          day: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
        },
      },
      form: {
        dateFrom: "",
        dateTo: "",
        checkingSavingAccount: {
          accountNumber: "",
          cifIdName: "",
        },
        savingAccount: {
          header: {
            accountNumber: 0,
            name: "",
            balanceEnd: 0,
            balanceMinimum: 0,
            holdNominal: 0,
            activeBalance: 0,
            branchCode: 0,
            branchName: "",
            cifAddress: "",
            startDate: "",
            endDate: "",
          },
        },
        officeName: "",
        transactionDate: "",
      },
      session: {
        checkingSavingAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      options: {
        urutkanBerdasarkan: [
          { value: "ASC", text: "Terlama - Terbaru" },
          { value: "DESC", text: "Terbaru - Terlama" },
        ],
      },
      table: {
        header: {
          transaction: [
            {
              key: "transactionDate",
              label: "Tanggal",
            },
            {
              key: "documentNo",
              label: "No Bukti Dokumen",
            },
            {
              key: "transactionNo",
              label: "No Transaksi",
            },
            {
              key: "description",
              label: "Keterangan",
            },
            {
              key: "debit",
              label: "Mutasi Debet",
            },
            {
              key: "credit",
              label: "Mutasi Kredit",
            },
            {
              key: "balanceEnd",
              label: "Saldo Akhir",
            },
          ],
        },
        data: {
          transaction: [],
          savingAccount: [],
        },
      },
    };
  },
  methods: {
    Moment: moment,
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        accountNumber: this.property.filterDto.accountNumber,
        dateTo: this.form.dateTo,
        dateFrom: this.form.dateFrom,
        sorting: this.property.filterDto.sorting,
      };
      this.property.animation.CheckingSavingAccount.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "account-statement/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Account_Statement.${fileType === "xls" ? "xlsx" : "pdf"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      } catch (error) {
      } finally {
        this.property.animation.CheckingSavingAccount.isDownloadingFile = false;
      }
    },
    handleErrorGetSavingAccount(error) {
      console.log(error.response);
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async checkingAvailabilitySavingAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
        this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
        this.form.checkingSavingAccount.accountNumber = "";
        this.form.checkingSavingAccount.cifIdName = "";

        try {
          this.property.animation.CheckingSavingAccount.isLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account/cross-office/simple-list",
            payload: {
              accountNumber: this.session.checkingSavingAccount.accountNumber,
              idCardNumber: "",
              cifIdName: this.session.checkingSavingAccount.cifIdName,
              dateFrom: this.property.filterDto.dateFrom,
              dateTo: this.property.filterDto.dateTo,
              page: 0,
              size: this.property.listElement.savingAccount.perPage,
            },
          });
          await this.simpleWait(500);
          if (resp.data.code === "SUCCESS") {
            this.form.savingAccount.header.startDate = "";
            this.form.savingAccount.header.endDate = "";
            this.table.data.savingAccount = resp.data.data.content;
            this.property.modal.showModalCheckingSavingAccount = false;
            this.property.modal.showModalResultListSavingAccount = true;
            this.property.listElement.savingAccount.rows =
              resp.data.data.totalElements;
          } else if (resp.data.code === "DATA NOT FOUND") {
            this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
            this.form.checkingSavingAccount.accountNumber = "";
            this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
            this.form.checkingSavingAccount.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `Nomor Rekening Tidak Tersedia!`,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
            this.form.checkingSavingAccount.accountNumber = "";
            this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
            this.form.checkingSavingAccount.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          await this.simpleWait(500);
          this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
          this.form.checkingSavingAccount.accountNumber = "";
          this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
          this.form.checkingSavingAccount.cifIdName = "";
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(500);
          this.property.animation.CheckingSavingAccount.isLoading = false;
        }
      }
    },
    clearModalResultCheckingSavingAccount() {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.perPage = 5;
    },
    async changePaginationSavingAccount(event) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/cross-office/simple-list",
          payload: {
            accountNumber: this.session.checkingSavingAccount.accountNumber,
            idCardNumber: "",
            cifIdName: this.session.checkingSavingAccount.cifIdName,
            page: event - 1,
            size: this.property.listElement.savingAccount.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccount = resp.data.data.content;
          this.property.listElement.savingAccount.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetSavingAccount(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetSavingAccount(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccount.downloading = false;
      }
    },
    failedGetSavingAccount(resp) {
      this.table.data.savingAccount = [];
      this.property.listElement.sav;
      ingAccount.rows = 0;
      this.property.listElement.savingAccount.message = resp.data.message;
    },
    closeModalCheckingSavingAccount() {
      this.property.modal.showModalCheckingSavingAccount = false;
      this.clearModalCheckingSavingAccount();
    },
    clearModalCheckingSavingAccount() {
      this.form.checkingSavingAccount.accountNumber = "";
      this.form.checkingSavingAccount.cifIdName = "";
      this.property.filterDto.dateFrom = "";
      this.property.filterDto.dateTo = "";
    },
    // closeModalCheckingSavingAccount() {
    //   this.property.modal.showModalSearchSavingAccount = false;
    //   this.property.filterDto.accountNumber = "";

    // },
    // clearModalCheckingSavingAccount() {
    //   this.property.filterDto.accountNumber = "";
    //   this.property.filterDto.dateFrom = "";
    //   this.property.filterDto.dateTo = "";
    // },
    async getSavingAccountDetailAccountStatement(props) {
      this.property.animation.CheckingSavingAccount.isLoading = true;
      this.property.filterDto.accountNumber = props.row.accountNumber;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "account-statement",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            dateTo: this.property.filterDto.dateTo,
            dateFrom: this.property.filterDto.dateFrom,
            sorting: this.property.filterDto.sorting,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.clearModalResultCheckingSavingAccount();
          this.table.data.transaction = [];
          this.property.listElement.accountStatement.downloading = true;
          this.property.modal.showModalResultListSavingAccount = false;
          this.form.dateFrom = this.property.filterDto.dateFrom;
          this.form.dateTo = this.property.filterDto.dateTo;

          this.clearModalCheckingSavingAccount();
          await this.simpleWait(500);
          this.form.savingAccount.header.accountNumber =
            resp.data.data.header.accountNumber;
          this.form.savingAccount.header.name = resp.data.data.header.qqName
            ? `${resp.data.data.header.name} ${resp.data.data.header.qqCode} ${resp.data.data.header.qqName}`
            : resp.data.data.header.name;
          this.form.savingAccount.header.balanceEnd =
            resp.data.data.header.balanceEnd;
          this.form.savingAccount.header.balanceMinimum =
            resp.data.data.header.balanceMinimum;
          this.form.savingAccount.header.holdNominal =
            resp.data.data.header.holdNominal;
          this.form.savingAccount.header.activeBalance =
            resp.data.data.header.activeBalance;
          this.form.savingAccount.header.branchCode =
            resp.data.data.header.branchCode;
          this.form.savingAccount.header.branchName =
            resp.data.data.header.branchName;
          this.form.savingAccount.header.cifAddress =
            resp.data.data.header.cifAddress;
          if (resp.data.data.detail) {
            this.table.data.transaction = resp.data.data.detail;
            this.form.transactionDate = resp.data.data.detail.transactionDate;
            this.preparePeriode();
          } else {
            this.table.data.transaction = [];
            this.property.listElement.accountStatement.message =
              "Belum Ada Riwayat Transaksi";
          }
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
            timer: 2000,
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
          timer: 2000,
        });
      } finally {
        await this.simpleWait(1000);
        this.property.listElement.accountStatement.downloading = false;
        this.property.animation.CheckingSavingAccount.isLoading = false;
      }
    },
    exportPdf() {
      const getUserPayloadFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.$refs.savingAccountStatementListPdfRef.onOpenPreview({
        tableData: this.table.data.transaction,
        letterhead: {
          printDate: `${moment().format("DD-MM-YYYY")} ${moment().format(
            "HH:mm:ss"
          )}`,
          name: this.form.savingAccount.header.name,
          cifAddress: this.form.savingAccount.header.cifAddress,
          activeBalance: this.form.savingAccount.header.activeBalance,
          holdNominal: this.form.savingAccount.header.holdNominal,
          balanceMinimum: this.form.savingAccount.header.balanceMinimum,
          balanceEnd: this.form.savingAccount.header.balanceEnd,
          accountNumber: this.form.savingAccount.header.accountNumber,
          branchName: this.form.savingAccount.header.branchName,

          filteredDate: `${this.dateToYearMonthDay(
            this.table.data.transaction[0].transactionDate
          )} s/d ${this.dateToYearMonthDay(
            this.table.data.transaction[this.table.data.transaction.length - 1]
              .transactionDate
          )}  `,
        },
        printBy: `${getUserPayloadFromSession.userName} ${moment().format(
          "DD-MM-YYYY"
        )} ${moment().format("HH:mm:ss")}`,
      });
    },
    printSavingAccountStatement() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      const headerFinal = [];
      const header = this.table.header.transaction.map((index) => {
        headerFinal.push(index.label);
      });
      const finalDataTable = [];
      var numbering = 1;
      const loopDataTable = this.table.data.transaction.map((index) => {
        finalDataTable.push([
          `${
            index.transactionDate
              ? new Date(index.transactionDate).getDate()
              : ""
          } ${
            index.transactionDate
              ? this.property.accessories.day[
                  new Date(index.transactionDate).getMonth()
                ]
              : ""
          } ${
            index.transactionDate
              ? new Date(index.transactionDate).getFullYear()
              : ""
          }`,
          index.documentNo,
          index.transactionNo,
          index.description,
          `${
            index.debit
              ? index.debit.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.credit
              ? index.credit.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.balanceEnd
              ? index.balanceEnd.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
        ]);
      });

      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc
        .setFontSize(10)
        .text(`Laporan Rekening Koran`, pageWidth / 2, 0.5, "center");
      doc
        .setFontSize(8)
        .text(
          `Kantor Cabang  ${this.form.savingAccount.header.branchName}`,
          0.55,
          1.2
        );
      doc.setFontSize(8).text(`Alamat Kantor Cabang`, 0.55, 1.42);
      doc
        .setFontSize(8)
        .text(
          `Nomor Rekening :    ${this.form.savingAccount.header.accountNumber}`,
          pageWidth / 2 + 1,
          1.2
        );
      doc
        .setFontSize(8)
        .text(
          `Saldo Akhir :    ${
            this.form.savingAccount.header.balanceEnd
              ? this.form.savingAccount.header.balanceEnd
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          pageWidth / 2 + 1,
          1.4
        );
      doc.setFontSize(8).text(`Kepada Yth,`, 0.55, 1.7);
      doc
        .setFontSize(8)
        .text(
          `Saldo Minimum :    ${
            this.form.savingAccount.header.balanceMinimum
              ? this.form.savingAccount.header.balanceMinimum
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          pageWidth / 2 + 1,
          1.6
        );
      doc
        .setFontSize(8)
        .text(`${this.form.savingAccount.header.name}`, 0.55, 1.9);
      doc
        .setFontSize(8)
        .text(
          `Jumlah Blokir :    ${
            this.form.savingAccount.header.holdNominal
              ? this.form.savingAccount.header.holdNominal
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          pageWidth / 2 + 1,
          1.8
        );
      doc
        .setFontSize(8)
        .text(`${this.form.savingAccount.header.cifAddress}`, 0.55, 2.1);
      doc
        .setFontSize(8)
        .text(
          `Saldo Yang Bisa Digunakan : ${
            this.form.savingAccount.header.activeBalance !== null
              ? this.form.savingAccount.header.activeBalance
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          pageWidth / 2 + 1,
          2.0
        );
      doc
        .setFontSize(8)
        .text(
          `Periode ${this.form.savingAccount.header.startDate} s/d ${this.form.savingAccount.header.endDate}`,
          pageWidth / 2 + 1,
          2.2
        );
      doc.autoTable({
        columnStyles: {
          0: {
            halign: "center",
            font: "courier",
            fontSize: 7,
            fontStyle: "normal",
          },
          1: {
            halign: "center",
            font: "courier",
            fontSize: 7,
            fontStyle: "normal",
          },
          2: {
            halign: "center",
            font: "courier",
            fontSize: 7,
            fontStyle: "normal",
          },
          3: {
            halign: "center",
            font: "courier",
            fontSize: 7,
            fontStyle: "normal",
          },
          4: {
            halign: "center",
            font: "courier",
            fontSize: 7,
            fontStyle: "normal",
          },
          5: {
            halign: "center",
            font: "courier",
            fontSize: 7,
            fontStyle: "normal",
          },
          6: {
            halign: "center",
            font: "courier",
            fontSize: 7,
            fontStyle: "normal",
          },
        },
        headStyles: {
          halign: "center",
          fontSize: 7.4,
          fontStyle: "bold",
          font: "normal",
        },
        head: [headerFinal],
        theme: "striped",
        body: finalDataTable,
        margin: { top: 2.7 },
        halign: "center",
        didDrawPage: function(data) {
          data.settings.margin.top = 0.9;
        },
      });
      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    async defaultPeriodeDate() {
      this.form.savingAccount.header.startDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
      this.form.savingAccount.header.endDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
    preparePeriode() {
      if (this.form.dateFrom !== "" && this.form.dateTo !== "") {
        this.form.savingAccount.header.startDate = `${new Date(
          this.form.dateFrom
        ).getDate()} ${
          this.property.accessories.day[new Date(this.form.dateFrom).getMonth()]
        } ${new Date(this.form.dateFrom).getFullYear()}`;

        this.form.savingAccount.header.endDate = `${new Date(
          this.form.dateTo
        ).getDate()} ${
          this.property.accessories.day[new Date(this.form.dateTo).getMonth()]
        } ${new Date(this.form.dateTo).getFullYear()}`;
      } else {
        this.defaultPeriodeDate();
      }
    },
    async sortingSavingAccountStatement(event) {
      this.table.data.transaction = [];
      this.property.listElement.accountStatement.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "account-statement",
          payload: {
            accountNumber: this.form.savingAccount.header.accountNumber,
            dateFrom: this.form.dateFrom,
            dateTo: this.form.dateTo,
            sorting: event,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          if (resp.data.data.detail) {
            this.table.data.transaction = resp.data.data.detail;
            this.preparePeriode();
          } else {
            this.table.data.transaction = [];
            this.property.listElement.accountStatement.message =
              "Belum Ada Transaksi";
          }
        } else {
          this.table.data.transaction = [];
          this.property.listElement.accountStatement.message =
            resp.data.message;
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorAccountStatement(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.accountStatement.downloading = false;
      }
    },
    handleErrorAccountStatement(error) {
      console.log(error.response);
      this.property.listElement.accountStatement.message = error.response
        ? `${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    prepareSavingAccountStatement() {
      this.property.filterDto.sorting = "ASC";
      const getAccountNumberFromSession = sessionStorage.getItem(
        "ACCOUNT_NUMBER_FROM_BALANCE_INQUIRY"
      );
      if (getAccountNumberFromSession) {
        this.form.checkingSavingAccount.accountNumber = getAccountNumberFromSession;
        this.property.modal.showModalCheckingSavingAccount = true;
      }
    },
    clearIdentifierAccountNumberFromBalanceInquirySessionStorage() {
      sessionStorage.removeItem("ACCOUNT_NUMBER_FROM_BALANCE_INQUIRY");
    },
  },

  mounted() {
    moment.locale("ID");
    this.prepareSavingAccountStatement();
  },
  beforeDestroy() {
    this.clearIdentifierAccountNumberFromBalanceInquirySessionStorage();
  },
};
